<!--
 * @Description:
 * @Author: Rabbiter
 * @Date: 2023-03-04 15:31:03
-->
<template>
    <div
        style="
            text-align: center;
            height: 100%;
            padding: 20px 0 0 0;
            margin: 0px;
        "
    >
        <div class="welcome">
            <b>
                <span> 欢迎使用假条生成系统，</span>
                <span style="color: black">
                    {{ user.name }}
                </span>
            </b>
        </div>

        <br />
        <br />
        <h5 style="font-size: 26px">
            <span style="color: grey; font-size: 22px">您当前的角色是：</span>
            {{
                user.roleId == 0
                    ? "超级管理员"
                    : user.roleId == 1
                    ? "管理员"
                    : "用户"
            }}
        </h5>
        <DateUtils></DateUtils>

        <div style="text-align: center; height: 18vw">
            <img
                :src="require('@/assets/background.jpg')"
                style="height: 100%"
            />
        </div>
    </div>
</template>

<script>
import DateUtils from "./DateUtils";
export default {
    name: "Home",
    components: { DateUtils },
    data() {
        return {
            user: {},
        };
    },
    computed: {},
    methods: {

        init() {
            this.user = JSON.parse(sessionStorage.getItem("CurUser"));
        },
      async fetchInfoData() {
        this.$axios
            .post(this.$httpUrl + "/info/list", this.user)
            .then((res) => res.data)
            .then((res) => {
              if (res!=null) {
                //存储
                sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(res)
                );

              } else {
                alert("请求失败！");
                return false;
              }
            })
            .catch((e) => {
              this.confirm_disabled = false;
              console.log(e);
              if (
                  e.response == undefined ||
                  e.response.data == undefined
              ) {
                this.$message({
                  showClose: true,
                  message: e,
                  type: "error",
                  duration: 5000,
                });
              } else {
                this.$message({
                  showClose: true,
                  message: e.response.data,
                  type: "error",
                  duration: 5000,
                });
              }
            });
        console.log("this.infolist"+this.infoList);

      },
    },
    created() {
        this.init();
      this.fetchInfoData();
    },
};
</script>

<style scoped>
.welcome {
    font-size: 30px;
    color: grey;
}
</style>
